
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../../helpers';
import MapBaseView from './MapBase.vue';

@Component
export default class MapVersionsView extends Vue {
    private mapDetails: starc.MapDetails | null = null;
    private versionHistory: starc.MapVersionHistory | null = null;
    private itemsPerPage = 20;
    private itemsPerPageArray = [20, 40, 60, 80, 100, 150, 200];
    private page = 1;
    private search = '';

    private get numberOfPages() {
        return Math.ceil(this.versionHistory!.versions.length / this.itemsPerPage)
    }

    private nextPage() {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
    }

    private formerPage() {
        if (this.page - 1 >= 1) this.page -= 1
    }

    private updateItemsPerPage(number: number) {
        this.itemsPerPage = number
        this.page = 1;
    }

    private customFilter(items: starc.MapHeader[], search: string) {
        return items.filter(x => {
            const currVer = `v${x.majorVersion}.${x.minorVersion}`;
            if (currVer.indexOf(search) !== -1) return true;

            const dateUploaded = this.$dfns.lightFormat(new Date(x.uploadedAt), 'yyyy-MM-dd')
            if (dateUploaded.indexOf(search) !== -1) return true;

            return false;
        });
    }

    @SGuard({
        onHttpError: function (this, err) {
            if (err.response!.status === 403) {
                (this.$parent as MapBaseView).isAccessRestricted = true;
                return true;
            }
        },
    })
    private async fetchHistory() {
        this.versionHistory = (await this.$starc.getMapVersionHistory(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId)
        )).data;
    }

    @SGuard()
    private async loadMapDetails() {
        this.mapDetails = (await this.$starc.getMapDetails(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId),
            {
                majorVersion: Number(this.$route.query.majorVersion),
                minorVersion: Number(this.$route.query.minorVersion),
            }
        )).data;
    }
    @Watch('$route')
    private reloadMapDetails() {
        if (typeof this.$route.query.majorVersion !== 'undefined' && typeof this.$route.query.minorVersion !== 'undefined') {
            this.loadMapDetails();
        }
        else {
            this.mapDetails = null;
        }
    }

    private async created() {
        await this.fetchHistory();
        await this.reloadMapDetails();
    }
}
