
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../../helpers';
import MapBaseView from './MapBase.vue';

@Component
export default class MapInfoView extends Vue {
    private mapDetails: starc.MapDetails | null = null;
    private dialog = {};
    private lobbyDelayValues = [
        '3',
        '5',
        '7',
        '10',
        '15',
        '20',
        '25',
        '30',
    ];

    private attributeRestrictionLabel(kind: starc.AttributeRestrictionKind) {
        return starc.AttributeRestrictionKind[kind];
    }

    private getDefaultAttrValueOfVariant(variant: starc.Variant<string | null>, attrId: number) {
        return variant.attributeDefaults.find(x => {
            return x.attribute.namespace === 999 && x.attribute.id === attrId;
        });
    }

    private getLobbyDelayOfVariant(variant: starc.Variant<string | null>) {
        const dvalue = this.getDefaultAttrValueOfVariant(variant, 3006);
        if (!dvalue) {
            return 10;
        }
        return Number(this.lobbyDelayValues[(dvalue!.value as starc.AttributeValue).index]);
    }

    private get mapInfo() {
        if (!this.mapDetails) return null;
        return this.mapDetails.info;
    }

    private get mapThumbnail() {
        if (!this.mapInfo) return null;
        const img = this.mapInfo.workingSet.thumbnail ?? this.mapInfo.workingSet.bigMap;
        if (!img) return null;
        return this.$starc.depotImage(img, this.mapInfo.meta.region);
    }

    private get arcadeScreenshots() {
        if (!this.mapInfo || !this.mapInfo.arcadeInfo) return null;
        const screenshots = [
            ...this.mapInfo.arcadeInfo.gameInfoScreenshots,
            ...this.mapInfo.arcadeInfo.howToPlayScreenshots,
        ].map(x => {
            return {
                caption: x.caption,
                picture: this.$starc.depotImage(x.picture, this.mapInfo!.meta.region)
            } as starc.MapScreenshot<string | null, starc.MapImageResolved>;
        })
        if (!screenshots.length) return null;
        return screenshots;
    }

    private get patchNotes() {
        if (!this.mapInfo || !this.mapInfo.arcadeInfo || !this.mapInfo.arcadeInfo.patchNoteSections) return null;
        return this.mapInfo.arcadeInfo.patchNoteSections.reverse();
    }

    private get publicAttributes() {
        if (!this.mapInfo) return null;
        return this.mapInfo.attributes.filter(x => x.access !== starc.AttributeRestrictionKind.None);
    }

    @SGuard({
        supressErrorCodes: [404],
        onHttpError: function (this, err) {
            if (err.response!.status === 403) {
                (this.$parent as MapBaseView).isAccessRestricted = true;
                return true;
            }
        },
    })
    private async fetchDetails() {
        this.mapDetails = (await this.$starc.getMapDetails(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId)
        )).data;
    }

    private async created() {
        await this.fetchDetails();
    }
}
