
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import MapItem from '@/components/MapItem.vue';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../../helpers';

@Component({
    components: {
        MapItem,
    },
})
export default class MapListView extends Vue {
    private itemsPerPageArray = [40, 80, 120, 160, 200];
    private mapList: starc.MapListResponse | null = null;
    private mapQueryParams!: starc.MapListQuery
    private currentPaginationParams!: starc.CursorPaginationQuery;
    private sortByValue!: string;

    private regionsList = [
        { value: 1, text: 'US' },
        { value: 2, text: 'EU' },
        { value: 3, text: 'KR' },
        { value: 5, text: 'CN' },
    ];
    private mapTypesList = [
        { value: starc.MapType.MeleeMap, text: 'Melee Map' },
        { value: starc.MapType.ArcadeMap, text: 'Arcade Map' },
        { value: starc.MapType.ExtensionMod, text: 'Extension Mod' },
        { value: starc.MapType.DependencyMod, text: 'Dependency Mod' },
    ];
    private sortByList = [
        { value: 'updated,desc', text: 'Updated: Newest first' },
        { value: 'updated,asc', text: 'Updated: Oldest first' },
        { value: 'published,desc', text: 'Published: Newest first' },
        { value: 'published,asc', text: 'Published: Oldest first' },
        { value: 'name,asc', text: 'Name: A-Z' },
        { value: 'name,desc', text: 'Name: Z-A' },
        { value: 'popularity,desc', text: 'Popularity' },
    ];

    private get arcadeCategories() {
        return starc.availableCategories.filter(x => !x.isMelee);
    }

    private get mapResults() {
        if (!this.mapList) return [];
        return this.mapList.results;
    }

    private created() {
        this.applyParamsFromRouteQuery();
        this.refreshList();
    }

    private updateItemsPerPage(n: number) {
        this.currentPaginationParams.limit = n;
        this.renavigate();
    }

    private applyParamsFromRouteQuery() {
        this.mapQueryParams = {
            regionId: this.$route.query?.regionId ? (Number(this.$route.query.regionId)) : void 0,
            type: this.$route.query?.type ? (String(this.$route.query.type) as starc.MapType) : void 0,
            name: String(this.$route.query?.name ?? ''),
            mainCategoryId: this.$route.query?.mainCategoryId ? (Number(this.$route.query.mainCategoryId)) : void 0,
            showPrivate: this.$route.query?.showPrivate === 'true',
            orderBy: this.$route.query?.orderBy ? String(this.$route.query.orderBy) : 'updated',
            orderDirection: this.$route.query?.orderDirection ? String(this.$route.query.orderDirection) : 'desc',
        };
        this.currentPaginationParams = {
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : 60,
        };
        this.sortByValue = [this.mapQueryParams.orderBy, this.mapQueryParams.orderDirection].join(',');
    }

    @SGuard()
    private async refreshList() {
        this.mapList = (await this.$starc.getMapList({
            ...this.mapQueryParams,
            ...this.currentPaginationParams,
        })).data;
    }

    private renavigate() {
        this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$helpers.stringifyQueryParams(this.mapQueryParams),
                ...this.$helpers.stringifyQueryParams(this.currentPaginationParams),
            },
        })
        this.$vuetify.goTo(this.$parent!);
    }

    private pageGo(where: 'prev' | 'next') {
        this.currentPaginationParams.before = void 0;
        this.currentPaginationParams.after = void 0;
        if (where === 'next' && this.mapList!.page.next) {
            this.currentPaginationParams.after = this.mapList!.page.next;
        }
        else if (where === 'prev' && this.mapList!.page.prev) {
            this.currentPaginationParams.before = this.mapList!.page.prev;
        }
        else return;
        this.renavigate();
    }

    @Debounce({ time: 400 })
    private onInput() {
        if (this.mapQueryParams.name === String(this.$route.query.name ?? '')) return;
        this.onSubmit();
    }

    private onSubmit() {
        [ this.mapQueryParams.orderBy, this.mapQueryParams.orderDirection ] = this.sortByValue.split(',');
        this.currentPaginationParams.before = void 0;
        this.currentPaginationParams.after = void 0;
        this.renavigate();
    }

    private refresh(ev: Event) {
        ev.preventDefault();
        this.refreshList();
    }

    private reset() {
        this.$router.push({ name: this.$route.name as string });
    }

    @Watch('$route')
    private watchRoute() {
        this.applyParamsFromRouteQuery();
        this.refreshList();
    }
}
