
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import { SGuard } from '../../helpers';

@Component({
    components: {
        ProfileItem,
    },
})
export default class MapBaseView extends Vue {
    public isAccessRestricted: boolean = false;
    private mapInfo: starc.Map | null = null;
    private activeTab = null;

    private get author() {
        if (!this.mapInfo) return null;
        return this.mapInfo.author;
    }

    private get isPublic() {
        if (!this.mapInfo) return null;
        return !this.mapInfo.currentVersion.isPrivate;
    }

    private get mapCategory() {
        if (this.mapInfo === null) return null;
        return starc.availableCategories.find(x => x.id === this.mapInfo!.mainCategoryId);
    }

    private get tabs() {
        if (!this.mapInfo) return [];
        const tabs = [
            {
                name: 'Details',
                route: {
                    name: 'map_details', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            },
            {
                name: 'Versions',
                route: {
                    name: 'map_versions', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            },
            {
                name: 'Dependencies',
                route: {
                    name: 'map_dependencies', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            },
            {
                name: 'Reviews',
                route: {
                    name: 'map_reviews', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            },
        ];

        if (this.mapInfo.type !== starc.MapType.DependencyMod) {
            tabs.push({
                name: 'Stats',
                route: {
                    name: 'map_stats', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            });
            tabs.push({
                name: 'Player base',
                route: {
                    name: 'map_player_base', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            });
            tabs.push({
                name: 'Lobbies history',
                route: {
                    name: 'map_lobbies_history', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            });
            tabs.push({
                name: 'Match history',
                route: {
                    name: 'map_match_history', params: {
                        regionId: this.$route.params.regionId,
                        mapId: this.$route.params.mapId,
                    },
                },
            });
        }

        return tabs;
    }

    @SGuard()
    private async loadMap() {
        this.isAccessRestricted = false;
        this.mapInfo = (await this.$starc.getMapInfo(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId)
        )).data;
    }

    private async created() {
        await this.loadMap();
    }

    @Watch('$route')
    private async watchRoute() {
        if (
            !this.mapInfo ||
            this.mapInfo.regionId !== Number(this.$route.params.regionId) ||
            this.mapInfo.bnetId !== Number(this.$route.params.mapId)
        ) {
            await this.loadMap();
        }
    }
}
