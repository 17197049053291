
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../../helpers';
import { RawLocation } from 'vue-router';

@Component
export default class InfoBaseView extends Vue {
    private nav: {
        icon?: string | null;
        text: string;
        location: RawLocation;
    }[] = [
        {
            text: 'About',
            icon: 'fa-book',
            location: {
                name: 'info_about',
            },
        },
        {
            text: 'Donate',
            icon: 'fa-donate',
            location: {
                name: 'info_donate',
            },
        },
        {
            text: 'Discord Bot',
            icon: 'fab fa-discord',
            location: {
                name: 'info_discord_bot',
            },
        },
        {
            text: 'Contact',
            icon: 'fa-envelope',
            location: {
                name: 'info_contact',
            },
        },
    ];
}
