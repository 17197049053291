
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../../helpers';

@Component
export default class AccountSettingsView extends Vue {
    private settings: starc.MapAuthorPreferences | null = null;

    private async onSubmit() {
        await this.storeSettings();
    }

    @SGuard()
    private async storeSettings() {
        await this.$starc.saveAccontSettings(this.settings!);
        this.$toast.success('Settings updated!', {
            timeout: 3000,
            closeOnClick: true,
        });
    }

    @SGuard({
        onHttpError: function (this, err) {
            if (err.response!.status === 401) {
                this.$router.push({ name: 'account_auth' });
                return true;
            }
        }
    })
    private async pullSettings() {
        this.settings = (await this.$starc.getAccontSettings()).data;
    }

    async created() {
        await this.pullSettings();
    }
}
