
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SGuard } from '../../helpers';

@Component
export default class InfoDonateView extends Vue {
    private recentDonations: any[] | null = null;
    private paypalNickname: string = '';
    private paypalMessage: string = '';

    private get paypalItemName() {
        const o: string[] = [];
        o.push('sc2arcade.com');
        if (this.paypalNickname) {
            o.push(`[${this.paypalNickname}]`);
        }
        if (this.paypalMessage) {
            o.push(this.paypalMessage.replace(/\n/g, ' | '));
        }
        return o.join(' - ');
    }

    @SGuard()
    private async fetchRecentDonations() {
        this.recentDonations = (await this.$starc.getInfoDonations()).data.donations;
    }

    private created() {
        this.fetchRecentDonations();
    }
}
