
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../../helpers';
import ProfileBaseView from './Base.vue';

@Component
export default class ProfileMatchHistoryView extends Vue {
    private itemsPerPageArray = [25, 50, 100, 100, 200];
    private currentPaginationParams!: starc.CursorPaginationQuery;
    private matchHistoryResponse: starc.ProfileMatchHistoryResponse | null = null;

    @SGuard({
        onHttpError: function (this, err) {
            if (err.response!.status === 403) {
                (this.$parent as ProfileBaseView).isAccessRestricted = true;
                return true;
            }
        }
    })
    private async refreshList() {
        const profParams = {
            regionId: Number(this.$route.params.regionId),
            realmId: Number(this.$route.params.realmId),
            profileId: Number(this.$route.params.profileId),
        };
        this.matchHistoryResponse = (await this.$starc.getProfileMatches({
            ...profParams,
            ...this.currentPaginationParams,
        })).data;
    }

    private renavigate() {
        this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$helpers.stringifyQueryParams(this.currentPaginationParams),
            },
        });
        this.$vuetify.goTo(this);
    }

    private updateItemsPerPage(n: number) {
        this.currentPaginationParams.limit = n;
        this.renavigate();
    }

    private applyParamsFromRouteQuery() {
        this.currentPaginationParams = {
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : this.itemsPerPageArray[0],
        };
    }

    private pageGo(where: 'prev' | 'next') {
        this.currentPaginationParams.before = void 0;
        this.currentPaginationParams.after = void 0;
        if (where === 'next' && this.matchHistoryResponse!.page.next) {
            this.currentPaginationParams.after = this.matchHistoryResponse!.page.next;
        }
        else if (where === 'prev' && this.matchHistoryResponse!.page.prev) {
            this.currentPaginationParams.before = this.matchHistoryResponse!.page.prev;
        }
        else return;
        this.renavigate();
    }

    @Watch('$route')
    private async watchRoute() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    private async created() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }
}
