
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '@/helpers';

@Component
export default class ListNavToolbar extends Vue {
    @Prop({
        required: false,
        default: () => [25, 50, 100, 150, 200],
    })
    readonly itemsPerPageArray!: number[];

    @Prop({
        required: false,
        default: () => [],
    })
    readonly sortOptions!: { value: string; text: string; }[];

    @Prop({
        required: true,
    })
    readonly currentPaginationParams!: starc.CursorPaginationQuery;

    @Prop({
        required: true,
    })
    readonly currentPaginationResult!: starc.CursorPaginationResult<any>;

    private sortByValue: string | null = null;

    get sortByIndex(): number | null {
        if (this.sortByValue === null) return null;
        const idx = this.sortOptions.findIndex(x => x.value === this.sortByValue);
        return idx;
    }

    private created() {
        this.updateState();
    }

    private updateState() {
        if (this.sortOptions.length > 0) {
            this.sortByValue = [
                (this.currentPaginationParams as any).orderBy, (this.currentPaginationParams as any).orderDirection
            ].join(',');
        }
    }

    private async renavigate() {
        await this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$helpers.stringifyQueryParams(this.currentPaginationParams),
            },
        });
        await this.$vuetify.goTo(this.$parent!);
    }

    private updateItemsPerPage(n: number) {
        this.currentPaginationParams.limit = n;
        this.renavigate();
    }

    private updateSortByOption(idx: number) {
        this.sortByValue = this.sortOptions[idx].value;
        const tmp = this.sortByValue.split(',');
        (this.currentPaginationParams as any).orderBy = tmp[0];
        (this.currentPaginationParams as any).orderDirection = tmp[1];
        this.currentPaginationParams.before = void 0;
        this.currentPaginationParams.after = void 0;
        this.renavigate();
    }

    // private applyParamsFromRouteQuery() {
    //     this.currentPaginationParams = {
    //         before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
    //         after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
    //         limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : this.itemsPerPageArray[0],
    //     };
    // }

    private pageGo(where: 'prev' | 'next') {
        this.currentPaginationParams.before = void 0;
        this.currentPaginationParams.after = void 0;
        if (where === 'next' && this.currentPaginationResult!.page.next) {
            this.currentPaginationParams.after = this.currentPaginationResult!.page.next;
        }
        else if (where === 'prev' && this.currentPaginationResult!.page.prev) {
            this.currentPaginationParams.before = this.currentPaginationResult!.page.prev;
        }
        else return;
        this.renavigate();
    }

    @Watch('currentPaginationParams')
    private onPaginationParams() {
        this.updateState();
    }
}
