
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';

@Component
export default class ProfileItem extends Vue {
    @Prop({
        required: true,
    })
    readonly profile!: starc.ProfileBase;

    @Prop({
        default: false,
    })
    readonly showDiscriminator!: boolean;

    @Prop({
        default: 'maxw-64',
    })
    readonly portraitPreset!: starc.MediaPreset;

    private get regionCode() {
        return starc.GameRegion[this.profile.regionId];
    }

    created() {
    }
}
