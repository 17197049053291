
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import { SGuard } from '../../helpers';
import { Debounce } from 'vue-debounce-decorator';
import { subDays, formatISO } from 'date-fns';

@Component({
    components: {
        ProfileItem,
    },
})
export default class MapPlayerBaseView extends Vue {
    private itemsPerPageArray = [100, 200, 300, 400, 500];
    private queryParams!: starc.MapPlayerBaseParams & starc.CursorPaginationQuery;
    private playerBaseResponse: starc.MapPlayerBaseResponse | null = null;
    private sortByList = [
        { value: 'lobbiesStarted,desc', text: 'Lobbies started' },
        { value: 'lobbiesHostedStarted,desc', text: 'Lobbies hosted started' },
        // { value: 'lobbiesStarted,desc', text: 'Lobbies started: Highest first' },
        // { value: 'lobbiesStarted,asc', text: 'Lobbies started: Lowest first' },
        // { value: 'lobbiesHostedStarted,desc', text: 'Lobbies hosted: Highest first' },
        // { value: 'lobbiesHostedStarted,asc', text: 'Lobbies hosted: Lowest first' },
        // { value: 'name,asc', text: 'Name: A-Z' },
        // { value: 'name,desc', text: 'Name: Z-A' },
    ];
    private sortByValue!: string;
    private menuLastPlayedMin: boolean = false;

    private formatRelativeTime(seconds: number) {
        if (seconds <= 0) return 'none';
        return this.$helpers.formatDateDistanceToNow(this.$dfns.addSeconds(new Date(), -seconds));
    }

    @SGuard()
    private async refreshList() {
        this.playerBaseResponse = (await this.$starc.getMapPlayerBase(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId),
            {
                ...this.queryParams,
            }
        )).data;
    }

    private renavigate() {
        this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$helpers.stringifyQueryParams(this.queryParams),
            },
        });
        this.$vuetify.goTo(this);
    }

    private updateItemsPerPage(n: number) {
        this.queryParams.limit = n;
        this.renavigate();
    }

    private applyParamsFromRouteQuery() {
        this.queryParams = {
            name: this.$route.query?.name ? String(this.$route.query?.name) : void 0,
            lastPlayedMin: this.$route.query?.lastPlayedMin ? String(this.$route.query?.lastPlayedMin) : void 0,
            orderBy: this.$route.query?.orderBy ? String(this.$route.query.orderBy) : 'lobbiesStarted',
            orderDirection: this.$route.query?.orderDirection ? String(this.$route.query.orderDirection) : 'desc',
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : this.itemsPerPageArray[0],
        };
        // if (!this.queryParams.lastPlayedMin) {
        //     this.queryParams.lastPlayedMin = formatISO(subDays(new Date(), 90), { representation: 'date' });
        // }
        this.sortByValue = [this.queryParams.orderBy, this.queryParams.orderDirection].join(',');
    }

    private pageGo(where: 'prev' | 'next') {
        this.queryParams.before = void 0;
        this.queryParams.after = void 0;
        if (where === 'next' && this.playerBaseResponse!.page.next) {
            this.queryParams.after = this.playerBaseResponse!.page.next;
        }
        else if (where === 'prev' && this.playerBaseResponse!.page.prev) {
            this.queryParams.before = this.playerBaseResponse!.page.prev;
        }
        else return;
        this.renavigate();
    }

    @Debounce({ time: 800 })
    private onInput() {
        if (
            this.queryParams.name === String(this.$route.query.name ?? '')
        ) return;
        this.onSubmit();
    }

    private onSubmit() {
        [ this.queryParams.orderBy, this.queryParams.orderDirection ] = this.sortByValue.split(',');
        this.queryParams.before = void 0;
        this.queryParams.after = void 0;
        this.renavigate();
    }

    @Watch('$route')
    private async watchRoute() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    private async created() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }
}
