
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import ListNavToolbar from '@/components/ListNavToolbar.vue';
import MapReviewItem from '@/components/MapReviewItem.vue';
import { SGuard } from '../../helpers';
import { subDays, formatISO } from 'date-fns';

@Component({
    components: {
        ProfileItem,
        MapReviewItem,
        ListNavToolbar,
    },
})
export default class MapPlayerBaseView extends Vue {
    queryParams!: starc.MapBaseParams & starc.MapUserReviewsParams & starc.CursorPaginationQuery;
    response: starc.MapUserReviewsResponse | null = null;

    readonly sortOptions = [
        { value: 'updated,desc', text: 'Updated: Newest first' },
        { value: 'updated,asc', text: 'Updated: Oldest first' },
        { value: 'created,desc', text: 'Posted: Newest first' },
        { value: 'created,asc', text: 'Posted: Oldest first' },
        { value: 'rating,desc', text: 'Most favorable' },
        { value: 'rating,asc', text: 'Most critical' },
        { value: 'helpful,desc', text: 'Most helpful' },
    ];
    private sortByValue!: string;

    @SGuard()
    private async refreshList() {
        this.response = (await this.$starc.getMapUserReviews(this.queryParams)).data;
    }

    private applyParamsFromRouteQuery() {
        this.queryParams = {
            regionId: Number(this.$route.params.regionId),
            mapId: Number(this.$route.params.mapId),
            orderBy: this.$route.query?.orderBy ? String(this.$route.query.orderBy) : 'updated',
            orderDirection: this.$route.query?.orderDirection ? String(this.$route.query.orderDirection) : 'desc',
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : 50,
        };
        this.sortByValue = [this.queryParams.orderBy, this.queryParams.orderDirection].join(',');
    }

    @Watch('$route')
    async watchRoute() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    async created() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }
}
