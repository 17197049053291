
import { Component, Prop, Vue } from 'vue-property-decorator';
import MapItem from '@/components/MapItem.vue';
import ProfileItem from '@/components/ProfileItem.vue';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../helpers';

interface LobbyEvent {
    type: 'join' | 'left' | 'title' | 'open' | 'close' | 'completed';
    date: Date;
    profile?: starc.ProfileBase;
    message?: string;
}

@Component({
    components: {
        MapItem,
        ProfileItem,
    },
})
export default class LobbyView extends Vue {
    private refreshTimer: number | null = null;
    private lobby: starc.GameLobbyData | null = null;
    private matchSummary: starc.LobbyMatchSummary | null = null;
    private isMatchSummaryLoading = false;
    private readonly regionsList = starc.regionsList;

    private get teamSlots() {
        type rType = {[key: number]: {
            team: number;
            name: string;
            slots: starc.GameLobbySlot[],
        }};
        const results: rType = {};
        for (const currSlot of this.lobby!.slots!) {
            if (!results[currSlot.team]) {
                results[currSlot.team] = {
                    team: currSlot.team,
                    name: `Team ${currSlot.team}`,
                    slots: [],
                };
            }
            results[currSlot.team].slots.push(currSlot);
        }
        return results;
    }

    private get joinEvents() {
        if (!this.lobby) return [];

        let events: LobbyEvent[] = [];

        for (const item of this.lobby.joinHistory!) {
            events.push({
                type: 'join',
                date: new Date(item.joinedAt),
                profile: item.profile,
            });
            if (item.leftAt) {
                events.push({
                    type: 'left',
                    date: new Date(item.leftAt),
                    profile: item.profile,
                });
            }
        }

        for (const item of this.lobby.titleHistory!) {
            events.push({
                type: 'title',
                date: new Date(item.date),
                profile: item.profile,
                message: item.title,
            });
        }

        events = events.sort((a, b) => a.date.getTime() - b.date.getTime());
        if (this.lobby.closedAt !== null) {
            events.push({
                type: 'close',
                date: new Date(this.lobby.closedAt),
                message: this.lobby.status.toUpperCase(),
            });
        }
        if (this.matchSummary?.completedAt) {
            events.push({
                type: 'completed',
                date: new Date(this.matchSummary.completedAt),
                message: 'COMPLETED',
            });
        }
        events = events.reverse();
        events.push({
            type: 'open',
            date: new Date(this.lobby.createdAt),
            message: this.lobby.joinHistory!.length === 0 ? this.lobby.hostName : 'OPENED' ,
        });

        return events;
    }

    private getMatchPlayerResult(profile: starc.ProfileBase) {
        if (!this.matchSummary) return;
        return this.matchSummary?.profileMatches.find(x => {
            return x.profile.realmId === profile.realmId && x.profile.profileId === profile.profileId;
        });
    }

    private async refresh() {
        this.refreshTimer = null;
        this.lobby = (await this.$starc.getLobbyDetails(
            Number(this.$route.params.regionId),
            Number(this.$route.params.bnetBucketId),
            Number(this.$route.params.bnetRecordId)
        )).data;
        if (this.lobby.status === starc.GameLobbyStatus.Open) {
            this.refreshTimer = setTimeout(this.refresh.bind(this), 7500);
        }
        else if (this.lobby.status === starc.GameLobbyStatus.Started) {
            this.isMatchSummaryLoading = true;
            try {
                this.matchSummary = (await this.$starc.getLobbyMatchSummary(
                    Number(this.$route.params.regionId),
                    Number(this.$route.params.bnetBucketId),
                    Number(this.$route.params.bnetRecordId)
                )).data;
            }
            catch (err) {
                if (isAxiosError(err) && err.response?.status === 404) {
                    this.matchSummary = null;
                }
                else {
                    throw err;
                }
            }
            finally {
                this.isMatchSummaryLoading = false;
            }
        }
    }

    @SGuard()
    private async fetchData() {
        await this.refresh();
    }

    private async created() {
        await this.fetchData();
    }

    private beforeDestroy() {
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
            this.refreshTimer = null;
        }
    }

    private mounted() {
    }
}
