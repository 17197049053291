
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import { SGuard } from '../../helpers';
import { Debounce } from 'vue-debounce-decorator';

@Component({
    components: {
        ProfileItem,
    },
})
export default class ProfileListView extends Vue {
    private queryParams!: starc.ProfileListParams & starc.CursorPaginationQuery;
    private queryResponse: starc.ProfileListResponse | null = null;
    private itemsPerPageArray = [40, 80, 120, 160, 200];
    private sortByList = [
        { value: 'lastOnlineAt,desc', text: 'Last online: Newest' },
        { value: 'lastOnlineAt,asc', text: 'Last online: Oldest' },
        { value: 'name,asc', text: 'Name: A-Z' },
        { value: 'name,desc', text: 'Name: Z-A' },
        { value: 'profileId,asc', text: 'Profile ID: Lowest' },
        { value: 'profileId,desc', text: 'Profile ID: Highest' },
        { value: 'id,asc', text: 'Internal DB ID: Lowest' },
        { value: 'id,desc', text: 'Internal DB ID: Highest' },
    ];
    private sortByValue!: string;

    private get queryResults() {
        if (!this.queryResponse) return null;
        return this.queryResponse.results;
    }

    private get regionsList() {
        return starc.regionsList.map(x => {
            return { value: x.id, text: x.code };
        });
    }

    private updateItemsPerPage(n: number) {
        this.queryParams.limit = n;
        this.renavigate();
    }

    private applyParamsFromRouteQuery() {
        this.queryParams = {
            profileHandle: this.$route.query?.profileHandle ? String(this.$route.query.profileHandle) : void 0,
            regionId: this.$route.query?.regionId ? (Number(this.$route.query.regionId)) : void 0,
            name: String(this.$route.query?.name ?? ''),
            orderBy: this.$route.query?.orderBy ? String(this.$route.query.orderBy) : 'lastOnlineAt',
            orderDirection: this.$route.query?.orderDirection ? String(this.$route.query.orderDirection) : 'desc',
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : 60,
        };
        this.sortByValue = [this.queryParams.orderBy, this.queryParams.orderDirection].join(',');
    }

    @SGuard()
    private async refreshList() {
        this.queryResponse = (await this.$starc.getProfileList(this.queryParams)).data;
    }

    private async created() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    private renavigate() {
        this.$router.push({
            name: this.$route.name!,
            query: this.$helpers.stringifyQueryParams(this.queryParams),
        })
        this.$vuetify.goTo(this.$parent!);
    }

    private pageGo(where: 'prev' | 'next') {
        this.queryParams.before = void 0;
        this.queryParams.after = void 0;
        if (where === 'next' && this.queryResponse!.page.next) {
            this.queryParams.after = this.queryResponse!.page.next;
        }
        else if (where === 'prev' && this.queryResponse!.page.prev) {
            this.queryParams.before = this.queryResponse!.page.prev;
        }
        else return;
        this.renavigate();
    }

    @Debounce({ time: 400 })
    private onInput() {
        if (
            this.queryParams.name === String(this.$route.query.name ?? '') &&
            this.queryParams.profileHandle === String(this.$route.query.profileHandle ?? '')
        ) return;
        this.onSubmit();
    }

    private onSubmit() {
        [ this.queryParams.orderBy, this.queryParams.orderDirection ] = this.sortByValue.split(',');
        this.queryParams.before = void 0;
        this.queryParams.after = void 0;
        this.renavigate();
    }

    @Watch('$route')
    private watchRoute() {
        this.applyParamsFromRouteQuery();
        this.refreshList();
    }
}
