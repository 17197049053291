
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';

@Component
export default class MapItem extends Vue {
    @Prop({
        required: true,
    })
    readonly map!: starc.Map;

    private get regionCode() {
        return starc.GameRegion[this.map.regionId];
    }

    created() {
    }
}
