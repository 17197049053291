
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '@/helpers';

@Component
export default class LobbyHistoryList extends Vue {
    @Prop({
        required: true,
    })
    readonly lobbiesHistory!: starc.GameLobbyData[];

    @Prop({
        required: false,
    })
    readonly profileSelf?: starc.ProfileBase;

    private mapId: number | null = null;
    private showMapModColumn = true;

    private getMatchPlayerResult(lobby: starc.GameLobbyData, profile: starc.ProfileBase) {
        if (!lobby.match) return;
        return lobby.match?.profileMatches.find(x => {
            return x.profile.realmId === profile.realmId && x.profile.profileId === profile.profileId;
        });
    }

    created() {
        this.mapId = Number(this.$route.params.mapId);
        if (this.lobbiesHistory.length) {
            this.showMapModColumn = (
                Number(this.$route.params.mapId) !== this.lobbiesHistory[0].mapBnetId ||
                this.lobbiesHistory.findIndex(x => x.extModBnetId) !== -1
            );
        }
        else {
            this.showMapModColumn = false;
        }
    }
}
