
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../../helpers';
import ProfileBaseView from './Base.vue';

@Component
export default class MostPlayedView extends Vue {
    private mapStats: starc.ProfileMostPlayedResponse | null = null;

    @SGuard({
        onHttpError: function (this, err) {
            if (err.response!.status === 403) {
                (this.$parent as ProfileBaseView).isAccessRestricted = true;
                return true;
            }
            if (err.response!.status === 404) {
                return true;
            }
        }
    })
    private async fetchData() {
        this.mapStats = (await this.$starc.getProfileMostPlayed({
            regionId: Number(this.$route.params.regionId),
            realmId: Number(this.$route.params.realmId),
            profileId: Number(this.$route.params.profileId),
        })).data;
    }

    private async created() {
        await this.fetchData();
    }
}
