
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../../helpers';

@Component
export default class ProfileBaseView extends Vue {
    public isAccessRestricted: boolean = false;
    public activeTab = null;
    public profile: starc.ProfileInfo | null = null;

    @Emit('restrict')
    accessRestrict() {
        console.log('ev accessRestrict');
        this.isAccessRestricted = true;
    }

    get tabs() {
        if (!this.profile) return [];
        const dparams = {
            regionId: this.$route.params.regionId,
            realmId: this.$route.params.realmId,
            profileId: this.$route.params.profileId,
        };
        const tabs = [
            {
                name: 'Summary',
                route: { name: 'profile_summary', params: dparams },
            },
            {
                name: 'Most played',
                route: { name: 'profile_most_played', params: dparams },
            },
            {
                name: 'Lobbies history',
                route: { name: 'profile_lobbies_history', params: dparams },
            },
            {
                name: 'Match history',
                route: { name: 'profile_match_history', params: dparams },
            },
            {
                name: 'Map reviews',
                route: { name: 'profile_map_reviews', params: dparams },
            },
            {
                name: 'Published maps',
                route: { name: 'profile_published_maps', params: dparams },
            },
        ];
        return tabs;
    }

    @SGuard()
    async loadProfile() {
        this.isAccessRestricted = false;
        this.profile = (await this.$starc.getProfile({
            regionId: Number(this.$route.params.regionId),
            realmId: Number(this.$route.params.realmId),
            profileId: Number(this.$route.params.profileId),
        })).data;
    }

    async created() {
        await this.watchRoute();
    }

    @Watch('$route')
    private async watchRoute() {
        if (
            !this.profile ||
            this.profile.regionId !== Number(this.$route.params.regionId) ||
            this.profile.realmId !== Number(this.$route.params.realmId) ||
            this.profile.profileId !== Number(this.$route.params.profileId)
        ) {
            await this.loadProfile();
        }
    }

    async copyToClipboard(text: string) {
        await navigator.clipboard.writeText(text);
    }
}
