
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard } from '../helpers';

@Component
export default class StatsView extends Vue {
    public regionStats: starc.StatsRegionsData | null = null;
    private charts: Highcharts.Chart[] = [];

    readonly periodItemKinds = [
        'daily',
        'weekly',
        'monthly',
    ];
    public periodParams: {
        period: keyof typeof starc.StatsPeriodKind,
    } = {
        period: 'weekly',
    };

    readonly regionCodes = starc.regionsList.map(x => x.code);

    readonly dKeys = {
        'lobbiesHosted': 'Lobbies Hosted',
        'lobbiesStarted': 'Lobbies Started',
        'participantsTotal': 'Participants Total',
        'participantsUniqueTotal': 'Participants Unique Total',
    };

    readonly baseOpts: Highcharts.Options = {
        chart: {
            zoomType: 'x',
            panKey: 'shift',
            panning: {
                enabled: true,
                type: 'x',
            },
        },
        title: {
            text: '-',
        },
        legend: {
            enabled: true
        },
        tooltip: {
            shared: true,
            split: true,
        },
        xAxis: {
            crosshair: true,
        },
        yAxis: {
            title: {
                text: null
            },
        },
        series: [],
    }

    public get lobbiesChart() {
        const opts: Highcharts.Options = Object.assign({}, this.$helpers.deepCopy(this.baseOpts));

        const name = 'lobbiesStarted';
        opts.title!.text = this.dKeys[name];
        (opts.xAxis as any).categories = this.regionStats!.date;

        for (const k of this.regionCodes) {
            (opts.series as Highcharts.SeriesColumnOptions[]).push({
                type: 'column',
                name: k,
                data: (this.regionStats as any)[name + k] as number[],
                borderWidth: 0,
                stacking: 'normal',
            });
        }

        return opts;
    }

    public get participantsChart() {
        const opts: Highcharts.Options = Object.assign({}, this.$helpers.deepCopy(this.baseOpts));

        const name = 'participantsTotal';
        opts.title!.text = this.dKeys[name];
        (opts.xAxis as any).categories = this.regionStats!.date;

        for (const k of this.regionCodes) {
            (opts.series as Highcharts.SeriesAreaOptions[]).push({
                type: 'area',
                name: k,
                data: (this.regionStats as any)[name + k] as number[],
                fillOpacity: 0.25,
            });
        }

        return opts;
    }

    public get participantsUniqueChart() {
        const opts: Highcharts.Options = Object.assign({}, this.$helpers.deepCopy(this.baseOpts));

        const name = 'participantsUniqueTotal';
        opts.title!.text = this.dKeys[name];
        (opts.xAxis as any).categories = this.regionStats!.date;

        for (const k of this.regionCodes) {
            (opts.series as Highcharts.SeriesAreaOptions[]).push({
                type: 'area',
                name: k,
                data: (this.regionStats as any)[name + k] as number[],
                fillOpacity: 0.25,
            });
        }

        return opts;
    }

    @SGuard()
    async refresh() {
        this.regionStats = (await this.$starc.getStatsRegions({
            kind: this.periodParams.period as keyof typeof starc.StatsPeriodKind,
        })).data;
    }

    chartInit(chart: Highcharts.Chart) {
        this.charts.push(chart);
        // chart.pointer.reset = () => void 0;
    }

    onChartSyncMove(e: (MouseEvent|PointerEvent|TouchEvent)) {
        for (const chart of this.charts) {
            const pointer = chart.pointer.normalize(e);
            // const point = (chart.series[0] as any).searchPoint(pointer, true);
            const point = chart.pointer.findNearestKDPoint(chart.series, true, pointer);
            if (point) {
                // chart.tooltip.refresh(point); // Show the tooltip
                chart.xAxis[0].drawCrosshair(pointer, point); // Show the crosshair
            }
        }
    }

    private applyParamsFromRouteQuery() {
        this.periodParams = {
            period: this.$route.query?.period ? String(this.$route.query?.period) as keyof typeof starc.StatsPeriodKind : 'weekly',
        };
    }

    created() {
        this.applyParamsFromRouteQuery();
        this.refresh();
    }

    @Watch('$route')
    watchRoute() {
        this.applyParamsFromRouteQuery();
        this.refresh();
    }

    async renavigate() {
        await this.$router.push({
            name: this.$route.name!,
            query: {
                ...this.$helpers.stringifyQueryParams(this.periodParams),
            },
        });
        await this.$vuetify.goTo(this);
    }

    async onSubmit() {
        await this.renavigate();
    }
}

