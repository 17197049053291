
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';

@Component({
    components: {
        ProfileItem,
    },
})
export default class MapReviewItem extends Vue {
    @Prop({
        required: true,
    })
    readonly review!: starc.MapUserReview;

    public get reviewColor(): string {
        switch (this.review.rating) {
            case 5: return 'green';
            case 4: return 'teal';
            case 3: return 'blue';
            case 2: return 'orange';
            case 1: return 'red';
            default: return 'grey';
        }
    }

    created() {
    }
}
