
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../../helpers';
import MapBaseView from './MapBase.vue';

@Component
export default class MapVersionsView extends Vue {
    private mapDependency: starc.MapDependencyInfo | null = null;

    private get totalSize() {
        return this.mapDependency!.list
            .filter(x => x.mapHeader.archiveSize >= 256)
            .map(x => x.mapHeader.archiveSize)
            .reduce((prev, curr) => prev + curr, 0)
        ;
    }

    @SGuard({
        onHttpError: function (this, err) {
            if (err.response!.status === 403) {
                (this.$parent as MapBaseView).isAccessRestricted = true;
                return true;
            }
        },
    })
    private async fetchData() {
        this.mapDependency = (await this.$starc.getMapDependencies(
            Number(this.$route.params.regionId),
            Number(this.$route.params.mapId)
        )).data;
    }

    private async created() {
        await this.fetchData();
    }
}
