
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import { SGuard, deepCopy } from '@/helpers';

interface LobbyFilters {
    searchPhrase: string;
    regions: starc.GameRegion[];
}

function lobbySearchTest(lobby: starc.GameLobbyData, search: string) {
    if (
        (!lobby.map || lobby.map.name.toLowerCase().indexOf(search) === -1) &&
        (!lobby.extMod || lobby.extMod.name.toLowerCase().indexOf(search) === -1) &&
        // (lobby.lobbyTitle.toLowerCase().indexOf(search) === -1) &&
        // lobby.hostName.toLowerCase().indexOf(search) === -1
        lobby.hostName.toLowerCase() !== search
    ) {
        return false;
    }
    return true;
}

@Component({
    components: {
        ProfileItem,
    },
})
export default class OpenLobbiesView extends Vue {
    private queryParams: LobbyFilters = {
        regions: [starc.GameRegion.US, starc.GameRegion.EU, starc.GameRegion.KR],
        searchPhrase: '',
    };
    private localParams: LobbyFilters | null = null;
    private refreshTimer: number | null = null;
    private refreshProgress: number = 100;
    private refreshing: boolean = true;
    private readonly refreshInterval = 8000;

    private activeLobbies: starc.GameLobbyData[] | null = null;

    private get regionsList() {
        return starc.regionsList;
    }

    private get currentLobbies() {
        return this.activeLobbies?.filter(item => {
            if (this.queryParams.regions.findIndex(y => y === item.regionId) === -1) {
                return false;
            }

            if (this.queryParams.searchPhrase) {
                const searchList = this.queryParams.searchPhrase.split(';').map(x => x.trim().toLowerCase()).filter(x => x.length);
                if (searchList.length) {
                    let matches = false;
                    for (const currSearch of searchList) {
                        if (lobbySearchTest(item, currSearch)) {
                            matches = true;
                            break;
                        }
                    }
                    if (!matches) {
                        return false;
                    }
                }
            }

            if (item.slots?.length && !item.hostProfile) {
                item.hostProfile = item.slots.find(x => x.name === item.hostName)?.profile;
            }

            return true;
        }) ?? [];
    }

    private applyParamsFromRouteQuery() {
        if (this.$route.query?.searchPhrase) {
            this.queryParams.searchPhrase = String(this.$route.query.searchPhrase);
        }
        else {
            this.queryParams.searchPhrase = '';
        }

        if (this.$route.query?.regions) {
            this.queryParams.regions = String(this.$route.query.regions).split(',').map(Number).sort();
        }
        else {
            // this.queryParams.regions = Object.values(starc.GameRegion).filter(x => typeof x === 'number') as starc.GameRegion[];
            this.queryParams.regions = [starc.GameRegion.US, starc.GameRegion.EU, starc.GameRegion.KR];
        }

        // apply
        if (!this.localParams) {
            this.localParams = deepCopy(this.queryParams);
        }
        this.localParams.searchPhrase = this.queryParams.searchPhrase;
        this.localParams.regions = this.queryParams.regions;
    }

    private renavigate() {
        return this.$router.replace({
            name: this.$route.name!,
            query: this.$helpers.stringifyQueryParams(this.localParams),
        })
    }

    private onSubmit() {
        this.renavigate();
    }

    @Debounce({ time: 500 })
    private onInput() {
        if (this.localParams?.searchPhrase === this.queryParams.searchPhrase) return;
        this.onSubmit();
    }

    private async refreshList() {
        this.refreshTimer = null;
        this.refreshProgress = 0;
        this.refreshing = true;
        try {
            this.activeLobbies = (await this.$starc.getLobbiesActive({
                includeMapInfo: true,
                includeSlots: true,
                includeSlotsJoinInfo: false,
                includeJoinHistory: false,
                recentlyClosedThreshold: 0,
            })).data;
            this.refreshing = false;
            this.refreshProgress = 100;
        }
        catch (err) {
            console.error(err);
            this.activeLobbies = null;
        }
        // if (this.refreshProgress >= 100) {
        // }
        // else {
        //     this.refreshProgress += 5000 / this.refreshInterval * 100;
        // }
        this.refreshTimer = setTimeout(this.refreshList.bind(this), this.refreshInterval);
    }

    @SGuard()
    private async fetchData() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    private created() {
        this.fetchData();
    }

    private beforeDestroy() {
        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
            this.refreshTimer = null;
        }
    }

    @Watch('$route')
    private watchRoute() {
        this.applyParamsFromRouteQuery();
    }

    @Watch('currentLobbies')
    private watchLobbies() {
        const openRegions = this.regionsList.map(x => `${x.code}: ${this.activeLobbies?.filter(y => y.regionId === x.id).length ?? '?'}`);
        document.title = `${this.currentLobbies.length} open lobbies | ${openRegions.join(' ')}`;
    }
}
