
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as starc from '@/starc-api/starc';
import ProfileItem from '@/components/ProfileItem.vue';
import ListNavToolbar from '@/components/ListNavToolbar.vue';
import { SGuard } from '../../helpers';

@Component({
    components: {
        ProfileItem,
        ListNavToolbar,
    },
})
export default class MapMatchHistoryView extends Vue {
    private queryParams!: starc.MapMatchHistoryParams & starc.CursorPaginationQuery;
    private matchHistoryResponse: starc.MapMatchHistoryResponse | null = null;

    @SGuard()
    private async refreshList() {
        this.matchHistoryResponse = (await this.$starc.getMapMatchHistory(this.queryParams)).data;
    }

    private applyParamsFromRouteQuery() {
        this.queryParams = {
            regionId: Number(this.$route.params.regionId),
            mapId: Number(this.$route.params.mapId),
            orderBy: this.$route.query?.orderBy ? String(this.$route.query.orderBy) : void 0,
            orderDirection: this.$route.query?.orderDirection ? String(this.$route.query.orderDirection) : void 0,
            before: this.$route.query?.before ? String(this.$route.query?.before) : void 0,
            after: this.$route.query?.after ? String(this.$route.query?.after) : void 0,
            limit: this.$route.query?.limit ? Number(this.$route.query?.limit) : void 0,
        };
    }

    @Watch('$route')
    private async watchRoute() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }

    private async created() {
        this.applyParamsFromRouteQuery();
        await this.refreshList();
    }
}
