
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MapItem from '@/components/MapItem.vue';
import * as starc from '@/starc-api/starc';
import { SGuard, isAxiosError } from '../../helpers';

@Component({
    components: {
        MapItem,
    },
})
export default class ProfilePublishedMapsView extends Vue {
    private errorMsg: string | null = null;
    private maps: starc.Map[] | null = null;
    private mapQueryParams: starc.MapListQuery = {
        name: void 0,
        type: void 0,
        showPrivate: false,
    };

    private mapTypesList = [
        { value: starc.MapType.MeleeMap, text: 'Melee Map' },
        { value: starc.MapType.ArcadeMap, text: 'Arcade Map' },
        { value: starc.MapType.ExtensionMod, text: 'Extension Mod' },
        { value: starc.MapType.DependencyMod, text: 'Dependency Mod' },
    ];

    private get filteredMaps() {
        if (!this.maps) return null;
        return this.maps.filter(item => {
            if (typeof this.mapQueryParams.name === 'string' && this.mapQueryParams.name.length > 0) {
                const phrase = this.mapQueryParams.name.toLowerCase().trim();
                if (phrase.length && item.name.toLowerCase().indexOf(phrase) === -1) {
                    return false;
                }
            }
            if (typeof this.mapQueryParams.type === 'string') {
                if (this.mapQueryParams.type !== item.type.toString()) {
                    return false;
                }
            }
            // if (item.currentVersion.isPrivate && !this.mapQueryParams.showPrivate) {
            //     return false;
            // }
            return true;
        });
    }

    private async onFormChange() {
        await this.fetchData();
    }

    @SGuard({
        onHttpError: function (this: ProfilePublishedMapsView, err) {
            if (err.response!.status === 403) {
                this.maps = [];
                this.errorMsg = err.response!.data?.message ?? null;
                return true;
            }
        },
    })
    private async fetchData() {
        const profParams = {
            regionId: Number(this.$route.params.regionId),
            realmId: Number(this.$route.params.realmId),
            profileId: Number(this.$route.params.profileId),
        };
        this.errorMsg = null;
        this.maps = (await this.$starc.getMapList({
            authorHandle: this.$starc.profileHandle(profParams),
            showPrivate: this.mapQueryParams.showPrivate,
            orderBy: 'updated',
            limit: 500,
        })).data.results;
    }

    private async created() {
        await this.fetchData();
    }
}
