
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import * as starc from '@/starc-api/starc';
import UserNav from './components/UserNav.vue';

@Component({
    components: {
        UserNav,
    },
})
export default class App extends Vue {
    private isLoading = false;
    private drawer = false;
    private nav: {
        icon: string | null;
        text: string;
        location: RawLocation;
    }[] = [
        {
            icon: null,
            text: 'Open lobbies',
            location: {
                name: 'open_lobbies',
            },
        },
        {
            icon: null,
            text: 'Maps',
            location: {
                name: 'map_list',
            },
        },
        {
            icon: null,
            text: 'Profiles',
            location: {
                name: 'profile_list',
            },
        },
        {
            icon: null,
            text: 'Stats',
            location: {
                name: 'stats',
            },
        },
        {
            icon: null,
            text: 'About',
            location: {
                name: 'info_about',
            },
        },
    ];

    async created() {
        const userToken = localStorage.getItem('user_token');

        if (userToken) {
            this.$starc.axios.defaults.headers['Authorization'] = `Bearer ${userToken}`;
            try {
                const response = (await this.$starc.accountInfo()).data;
                this.$store.battleAccount = response.battleAccount;
            }
            catch (err) {
                if (this.$helpers.isAxiosError(err)) {
                    if (err.response?.status == 401) {
                        localStorage.removeItem('user_token');
                        this.$store.battleAccount = null;
                        return;
                    }
                }
                throw err;
            }
        }
    }
}

